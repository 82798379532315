*{
    margin: 0;
    padding: 0;
}

.home-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.home-normal-container{
    display: flex;
    justify-content: space-between;
}
.home-container .header-logo-icon{
    padding: 0;
}
.home-container .header-logo-icon .logo-image{
    display: flex;
    flex-direction: row;
}
.header-logo-icon .logo-image .log-1 img{
    width:130px;
    height:130px;
}
.header-logo-icon .logo-image .log-2{
    margin-left:-45px;
    margin-top:-18px;
}
.header-logo-icon .logo-image .log-2 img{
    width:200px;
    height:200px;
}
.navbar-container{
    display: flex;
    flex-direction: row;
}
.navbar_normal-contianer{
    display: flex;
    flex-direction: row;
    gap:14rem;
    margin-top:50px;
}
.navbar_normal-contianer .navbar_links nav ul{
    display: flex;
    flex-direction: row;
    gap:50px;
}
.navbar_normal-contianer .navbar_links nav ul li{
    list-style-type: none;
}
.navbar_normal-contianer .navbar_links nav ul li a{
    text-decoration: none;
    font-family: "Raleway", sans-serif;
    font-weight:600;
    color:#000;
    padding-top:7px;
    padding-bottom:1px;
    transition: border-bottom .8s ease;
    font-size:18px;
}
.navbar_number{
    display: flex;
    flex-direction: row;
}
.navbar_number a{
    display: flex;
    flex-direction: row;
    margin-right:40px;
    gap:10px;
    text-decoration: none;
}
.navbar_number a .phone_icon{
   margin-top: -3px;
}
.navbar_number a .phone_icon img{
    width:17px;
    height: 17px;
    padding:5px;
    background-color: #000;
}
.navbar_number a .phone_num p{
    display: flex;
    flex-direction: row;
    color:#000;
    font-family: "DM Sans", sans-serif;
    font-weight:600;
}
.navbar_number a .phone_num p a{
    padding-left:5px;
    color: #000;
}
.navbar_normal-contianer .navbar_links nav ul li a:hover{
    border-bottom: 1px solid #000;
}

.navbar-resp-container{
    display: none;
}
.resp-navbar-link{
    display:none;
}
@media(max-width:768px)
{
    .navbar_normal-contianer .navbar_links,
    .navbar_normal-contianer .navbar_number{
        display: none;
    }
   
    .navbar-resp-container{
        display: block;
    }
    .header-logo-icon .logo-image .log-1 img{
        width:80px;
        height:80px;
    }
    .header-logo-icon .logo-image .log-2{
        margin-left:-30px;
        margin-top:-5px;
    }
    .header-logo-icon .logo-image .log-2 img{
        width:110px;
        height:110px;
    }
    .menu-icon{
        display: flex;
        flex-direction: column;
        gap:5px;
        margin-top:28px;
        margin-right:20px;
        cursor: pointer;
    }
    .menu-1,
    .menu-2,
    .menu-3{
        background-color: #000;
        width:25px;
        height:3px;
        transition: all 0.4s ease;
    }
    .menu-icon.active .menu-1 {
        transform: rotate(45deg);
        position: relative;
        top: 8px;
    }
    
    .menu-icon.active .menu-2 {
        opacity: 0; 
        transition: opacity 0.3s ease;
    }
    
    .menu-icon.active .menu-3 {
        transform: rotate(-45deg);
        position: relative;
        top: -8px;
    }
    
    .menu-2 {
        opacity: 1;
        transition: opacity 0.3s ease;
    }
   
    .resp-navbar-link{
        display: block;
        background-color:#fff;
        width:80%;
        margin: 0 auto;
        overflow: hidden;
        height: 0; 
        opacity: 0;
        transition: height 0.4s ease, opacity 0.3s ease;
   }
  
   .resp-navbar-link.open {
       height: 200px;
       opacity: 1;
   }
    .resp-navbar-link .navbar_links_resp nav ul{
        display: flex;
        flex-direction: column;
        gap:20px;
        
    }
    .resp-navbar-link .navbar_links_resp nav ul li{
        padding-bottom:4px;
        padding-left:10px;
        list-style-type: none;
        border-bottom:1px solid #000;
    }
    .resp-navbar-link .navbar_links_resp nav ul li a{
        text-decoration: none;
        text-decoration: none;
        font-family: "Raleway", sans-serif;
        font-weight:600;
        color:#000;
        padding-top:7px;
        padding-bottom:2px;
        font-size:18px;

    }
}