*{
    margin: 0;
    padding: 0;
}

.about-container-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:95%;
    margin: 0 auto;
    margin-top:8rem;
    margin-bottom:30px;
}
.about-container-section .about-section{
  display: flex;
  flex-direction: column;
  gap:30px;
}
.about-container-section .about-section .about-head h1{
    width:80%;
    font-size:55px;
    font-family: "Raleway", sans-serif;
    font-weight:700;
    color:#000;
    word-spacing:8px;
    letter-spacing: 1px;
}
.about-container-section .about-section .about-heading-para p{
    width:82%;
    font-size:20px;
    font-family: "DM Sans", sans-serif;
    color:#3F3F3F;
    line-height:35px;
}
.about-container-section .about-section .about-contact {
    margin-top:18px;
}
.about-container-section .about-section .about-contact a{
    text-decoration: none;
    background-color: #CDEF5C;
    padding:15px 30px;
    font-size:18px;
    font-family: "DM Sans", sans-serif;
    color:#000;
    font-weight:600;
    letter-spacing:1px;
}
.about-container-section .about-section .about-contact a img{
    background-color: #000;
    margin-left:10px;
   margin-bottom:-9px;
    padding: 6px;
    width:17px;
    height: 17px;
}

.about-image-section{
    display: flex;
    flex-direction: row;
    justify-content: end;
}
.about-image-section .about-image{
    display: flex;
    flex-direction: row;
}
.about-image-section .about-image .about-1{
    margin-right:-80px;
    z-index: 0;
}
.about-1 img{
   width:350px;
   height:380px;
   
}
.about-small-content{
    display: flex;
    flex-direction: column;
    gap:150px;
}
.about-small-content .about-content{
    width:430px;
    height:430px;
    background-color: #CDEF5C;
    margin-top:-250px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.about-small-content .about-content .about-small-head {
    padding:50px 0px 0px 40px;
}
.about-small-content .about-content .about-small-head span{
    font-size:14px;
    padding-top:80px;
    font-family: "DM Sans", sans-serif;
    color:#000;
    font-weight:600;
    letter-spacing:5px;
    word-spacing:2px;
}
.about-small-content .about-content .about-small-description{
    padding:0px 20px 30px 40px;
}
.about-small-content .about-content .about-small-description h2{
   font-size:32px;
   font-family: "Raleway", sans-serif;
   font-weight:700;
    color:#000;
    letter-spacing:1px;
    word-spacing:2px;
}
.about-small-content .about-2{
    margin-left:200px;
    margin-right:-15px;
}
.about-small-content .about-2 img{
    width:90px;
    height: 90px;
}
@media(max-width:768px)
{
 .about-container-section{
    flex-direction: column;
    margin-top:30px;
 }
 .about-container-section .about-section .about-head h1{
    width:93%;
    margin: 0 auto;
    font-size:25px;
 }
 
 .about-container-section .about-section .about-heading-para p{
    width:85%;
    padding-left:13px;
    font-size:16px;
    line-height:25px;
    margin-top:-10px;
 }
 .about-container-section .about-section .about-contact a {
    font-size:16px;
    padding:15px 20px;
 }
 .about-container-section .about-section .about-contact a img{
    width:15px;
    height: 15px;
 }
 .about-image-section{
    flex-direction: column;
 }
 .about-small-content .about-content{
    margin-top: 30px;
    width:auto;
    height:280px;
 }
 .about-small-content .about-content .about-small-head {
    padding:40px 0px 0px 28px;
}
.about-small-content .about-content .about-small-description{
    padding:0px 20px 30px 28px;
}
.about-small-content .about-content .about-small-head span{
    font-size:12px;
}
.about-small-content .about-content .about-small-description h2{
    font-size:25px;
    line-height:32px;
}
.about-small-content .about-2 img{
    display: none;
}
 .about-image-section .about-image .about-1{
    margin-right:0px;
    margin-top:50px;
 }
 
 .about-container-section .about-section .about-contact {
    margin-left:15px;
 }
 .about-1 img{
    height:280px;
 }
}