*{
    margin: 0;
    padding: 0;
}
.test-container-section{
    display: flex;
    flex-direction: column;
    margin-top:8rem;
}
.testimonial-section{
    background-color: #000;
    width:95%;
    margin: 0 auto;
    height:620px;
    color:#fff;
}
.test-container-section .testimonial-section {
    display: flex;
    flex-direction: row;
    gap:0px;
}
.test-section-1,
.test-section-2{
    display: flex;
    flex-direction: column;
    gap:30px;
}
.test-section-1 .test-para p{
    padding:60px 0px 0px 80px;
    width:54%;
    font-family: "DM Sans", sans-serif;
    font-size:21px;
    line-height:45px;
    letter-spacing:1px;
    word-spacing:2px;
    font-weight:600;
    color:#fff;
}
.test-section-2 .test-para{
   margin-left:-150px;
}
.test-section-2 .test-para p
{
    padding:60px 0px 0px 0px;
    width:75%;
    font-family: "DM Sans", sans-serif;
    font-size:21px;
    line-height:45px;
    letter-spacing:1px;
    word-spacing:2px;
    font-weight:600;
    color:#fff;
}
.test-details{
    padding-left: 80px;
    display: flex;
    flex-direction: column;
    gap:5px;
}
.test-details-2{
    margin-left:-140px;
    display: flex;
    flex-direction: column;
    gap:5px;
}
.test-head h4{
    font-family: "Raleway", sans-serif;
    letter-spacing:1xp;
    word-spacing:2px;
}
.test-subhead span{
    font-family: "Raleway", sans-serif;
    color:#CDEF5C;
    letter-spacing:1xp;
    word-spacing:2px;
}
.test-container-section .testimonial-bottom-img {
    display:flex;
    justify-content: end;
    z-index:999;
    margin-top:-5rem;
}
.test-container-section .testimonial-bottom-img img{
    width:700px;
    height:auto;
}
@media(max-width:768px)
{
    .test-container-section{
        margin-top:-9rem;
    }
    .testimonial-section{
        height: auto;
    }
    .test-container-section .testimonial-section{
        flex-direction: column;
    }
    .test-section-1 .test-para p,
    .test-section-2 .test-para p{
        padding: 0;
        width:85%;
        margin: 0 auto;
        font-size:16px;
        line-height: 26px;
        letter-spacing:1px;
        word-spacing:3px;
        padding-top:40px;
        font-weight:550;
    }
    .test-details,
    .test-details-2{
        padding-left: 0px;
        width:85%;
        margin: 0 auto;
    }
    .test-section-2 .test-para{
        margin-left:0px;
        
    }
    .testimonial-bottom-img img{
        display: none;
    }
    .test-section-2{
        padding-bottom:2.5rem;
    }
}