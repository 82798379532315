*{
    margin: 0;
    padding: 0;
}
.footer-section-container{
    background-color: #fff;
}
.footer-section{
    width:95%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    gap:300px;
    padding-bottom:30px;
}
.footer-section .company-footer{
     display: flex;
     flex-direction: column;
     gap:0px;
}
.company-logo-footer{
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
}
.company-logo-footer .building-logo img{
    width:140px;
    height:140px;
    margin-top:12px;
}

.company-logo-footer .company-name img{
    width:200px;
    height: 200px;
    margin-left:-55px;
}
.company-social-link {
    display: flex;
    flex-direction: column;
    gap:20px;
    margin-top:-40px;
}
.company-social-link .instagram a,
.company-social-link .facebook a,
.company-social-link .whatsapp a{
    display: flex;
    width:63%;
    margin: 0 auto;
    gap:18px;
    text-decoration: none;
    font-family: "DM Sans", sans-serif;
    font-size:18px;
    align-items: center;
    color:#000;
    font-weight:600;
}

.company-social-link .instagram a .instagram-logo img,
.company-social-link .facebook a .facebook-logo img,
.company-social-link .whatsapp a .whatsapp-logo img{
   width:30px;
   height: 30px;
   padding-top:4px;
}
.footer-links,
.footer-services{
   margin-top:60px;
   display: flex;
   flex-direction: column;
   gap:30px;
}
.footer-services-name h3,
.company-name h3{
    font-family: "Raleway", sans-serif;
    font-size:21px;  
    text-transform: uppercase;
}
.services-name ul,
.footer-navLinks ul{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.services-name ul li,
.footer-navLinks ul li{
    list-style-type: none;
   font-family: "DM Sans", sans-serif;
   font-size:16px;
   font-weight:550;
   word-spacing:3px;
   cursor: pointer;
}
.footer-navLinks ul li a{
    text-decoration: none;
    color:#000;
}
.footer-copyright{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom:30px;
    width:95%;
    margin: 0 auto;
    border-top:1px solid #cdef5c;
    padding-top:30px;
}
.footer-copyright p{
    font-size:18px;
    font-family: "DM Sans", sans-serif;
    color:#000;
}
.footer-copyright p span{
    font-weight: bold;

}
.footer-links-nav{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   gap:250px;
}
@media(max-width:786px)
{
    .footer-section{
        width:100%;
        flex-direction: column;
        gap:40px;
        margin-top:-60px;
    }
    .company-logo-footer .building-logo img{
        width:100px;
        height: 100px;
    }
    .company-logo-footer .company-name img{
        width:150px;
        height: 150px;
        margin-left:-35px;
    }
    .company-social-link .instagram a,
    .company-social-link .facebook a,
    .company-social-link .whatsapp a
    {
        width:80%;
        font-size:17px;
    }
    .company-social-link .instagram a .instagram-logo img,
    .company-social-link .facebook a .facebook-logo img,
    .company-social-link .whatsapp a .whatsapp-logo img{
        width:25px;
        height:25px;
        padding-top:4px;
    }
    .footer-copyright{
        margin-bottom:20px;
    }
    .footer-links,
    .footer-services{
        width:80%;
        margin: 0 auto;
        gap:20px;
    }
    .footer-services-name h3,
    .company-name h3{
        font-size:19px;
        letter-spacing: 1px;
    }
    .footer-copyright p{
        font-size:16px;
        width:65%;
        margin: 0 auto;
        text-align: center;
        line-height:26px;
    }
    .footer-links-nav{
        display:flex;
        width:80%;
        margin: 0 auto;
        gap:60px;
    }
}