*{
    margin: 0;
    padding: 0;
}
.hero-container{
    display: flex;
    flex-direction: column;
    margin-top: -75px;
}
.hero-container .hero-section-container{
    display: flex;
    flex-direction: column;
    background-color: #F7F6F2;
    gap:30px;
}
.hero-container .hero-section-container .hero-heading{
    margin-top:80px;
    display: flex;
    flex-direction: row;
}
.hero-container .hero-section-container .hero-heading span{
    color:#9ec717b1;
}
.hero-container .hero-section-container .hero-heading h1{
    font-family: "Raleway", sans-serif;
    font-weight:720;
    font-size:90px;
    width:93%;
    margin: 0 auto;
}
.hero-container .hero-section-container .hero-para-heading{
    width:80%;
    margin-left: 2rem;
}
.hero-container .hero-section-container .hero-para-heading p{
    width:80%;
    margin: 0 auto;
    font-size:25px;
    word-spacing:3px;
    font-family: "DM Sans", sans-serif;
}

.hero-container .hero-section-container .three-div-header{
    display: flex;
    flex-direction: row;
    justify-content:end;
    margin-right:20px;
    gap:22px;
    margin-top:10px;
}
.hero-container .hero-section-container .three-div-header .div-1-products{
    background-color: #CDEF5C;
    width:410px;
    height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index:999;
}
.hero-container .hero-section-container .three-div-header .div-2-contact{
    background-color: #000;
    width:410px;
    height: 310px;
    z-index:999;
    margin-right:-215px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.div-1-products .hero-product-div h3,
.div-2-contact .hero-contact-div h3{
  padding:35px 0px 0px 26px;
  font-family: "DM Sans", sans-serif;
  text-transform: uppercase;
  font-size:15px;
  letter-spacing:5px;
  word-spacing: 3px;
}
.div-1-products .hero-prod-details,
.div-2-contact .hero-contact-details{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:0px 40px 40px 30px;
}
.div-1-products .hero-prod-details .prod-span p,
.div-2-contact .hero-contact-details .contact-span p{
   font-size:25px;
   width:90%;
   font-family: "DM Sans", sans-serif;
   font-weight:600;
   letter-spacing: 1px;
}
.div-1-products .hero-prod-details .right-arrow-hero a,
.div-2-contact .hero-contact-details .right-arrow-hero a{
    margin-top:10px;
}
.div-1-products .hero-prod-details .right-arrow-hero img{
    background-color: #fff;
    padding:15px;
    border-radius:55px;
}
.hero-contact-div h3,
.contact-span p{
    color:#CDEF5C;
}
.div-2-contact .hero-contact-details .right-arrow-hero img{
    background-color: #CDEF5C;
    padding:15px;
    border-radius:55px;
}
.hero-container .hero-section-container .div-3-img img {
    width:700px;
    height:550px;
    z-index:-50;
    margin-top:50px;
}
.images-gallery {
    display: flex;
    width: 99%;
    gap: 5px;
    margin: 0 auto;
    margin-top: -360px;
    position: relative;
    overflow: hidden;
}

.images-gallery::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right,#0000000c, #0000008d); 
    z-index: 1;
    pointer-events: none; 
}
.image-div1{
    display: flex;
    flex-direction: column;
    gap:5px;
}
.image-div1, .image-div2 {
    position: relative;
    z-index: 0; 
}

.image-div1 img,
.image-div2 img {
    display: block;
    width: 100%; 
    height: auto;
}

.image-div1 img {
    width: 600px;
    height: 322px;
}

.image-div2 img {
    width: 900px;
    height: 650px;
}

.right-bottom-link{
    display: flex;
    flex-direction: column;
    z-index: 999999;
}
.bottom-whatsapp img,
.bottom-call img {
    width: 55px;
    height: 55px;
    background-color: #f0f0f0; /* Updated to a light gray for a softer look */
    border-radius: 50%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adds a soft shadow for depth */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.bottom-whatsapp img:hover,
.bottom-call img:hover {
    background-color: #e0e0e0; /* Slightly darker shade on hover */
}

.bottom-right{
    position: fixed;
    right: 0;
    padding-right:40px;
}
.bottom-right{
    display: flex;
    flex-direction: column;
    gap:15px;
}
@media(max-width:768px)
{
    .hero-container{
        margin-top:-35px;
    }
    .hero-container .hero-section-container .hero-heading{
        flex-direction: column;
    }
    .hero-container .hero-section-container .hero-heading h1{
        font-size:28px;
        width:88%;
        margin: 0 auto;
        margin-top:-30px;

    }
    .hero-container .hero-section-container .hero-para-heading{
        width:100%;
        margin-left: 0rem;
    }
    .hero-container .hero-section-container .hero-para-heading p{    
        font-size:18px;
        width:85%;
        margin: 0 auto;
    }
    .hero-container .hero-section-container .three-div-header{
        flex-direction: column;
    }
    .hero-container .hero-section-container .three-div-header .div-1-products,
    .hero-container .hero-section-container .three-div-header .div-2-contact{
        width:85%;
        height:260px;
        margin: 0 auto;
    }
    .resp-navbar-link.open{
        padding-bottom:80px;
    }
    .div-1-products .hero-prod-details .prod-span p,
    .div-2-contact .hero-contact-details .contact-span p{
        font-size:18px;
    }
    .div-1-products .hero-prod-details .right-arrow-hero img,
    .div-2-contact .hero-contact-details .right-arrow-hero img{
        padding:13px;
        width:18px;
        height:18px;
    }
    .hero-container .hero-section-container .div-3-img img {
        display: none;
    }
    
    .images-gallery{
        margin-top:0px;
    }
    .image-div1 img{
        width:380px;
        height:200px;
    }
    .image-div2 img {
        display:none;
    }
    .bottom-right{
        margin-top:3rem;
    }
    .bottom-whatsapp img,
    .bottom-call img {
        width:48px;
        height:48px;
    
}
    .bottom-right{
        padding-right:18px;
    }
}