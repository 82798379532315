* {
    margin: 0;
    padding: 0;
}

.services-container-section {
    width: 95%;
    margin: 0 auto;
    margin-top: 7rem;
    margin-bottom: 5rem;
}

.services-container-section .services-section {
    display: flex;
    flex-direction: column;
    gap: 80px;
}


.services-head h1 {
    width: 60%;
    font-size: 50px;
    font-family: "Raleway", sans-serif;
    line-height: 70px;
    letter-spacing: 1px;
    word-spacing: 4px;
}

.services-photo-gallery .service-head-icon {
    display: flex;
    justify-content: space-between;
}

.services-container-section .services-photo-gallery .services-p1 {
    position: relative; 
    display: flex;
    flex-direction: column;
    gap: 13px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.services-photo-gallery .services-p1 .services-head-g h1 {
    font-family: "DM Sans", sans-serif;
    font-size: 23px;
    text-transform: uppercase;
    font-weight: 550;
    letter-spacing: 1px;
    color: #0F0F0F;
}

.services-container-section .services-section .services-photo-gallery {
    display: flex;
    gap: 80px;
    flex-wrap: wrap;
}

.services-photo-gallery .services-p1 .services-g {
    position: relative; 
    width: 420px;
    height: 240px;
    overflow: hidden; 
}

.services-photo-gallery .services-p1 .services-g img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
}

.services-photo-gallery .services-p1 .services-g::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(110, 110, 110, 0.24); 
    opacity: 1; 
}


.right-icon img {
    transition: transform 0.3s ease;
}

.services-p1:hover .right-icon img {
    transform: rotate(-45deg);
}

.services-desc {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}

.services-para p {
    width: 60%;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    word-spacing: 1px;
}

.link-icon {
    background-color: #CDEF5C;
    width: 240px;
    height: 55px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: -10px;
    cursor: pointer;
}

.service-link span {
    text-decoration: none;
    color: #0F0F0F;
    font-family: "DM Sans", sans-serif;
    font-weight: 600;
}

.service-right-icon {
    background-color: #0F0F0F;
    padding: 3px 5px;
}

.service-right-icon img {
    width: 18px;
    height: 18px;
}
@media(max-width:786px)
{
    .services-head h1{
        font-size:18px;
        line-height:28px;
        width:95%;
        margin: 0 auto;
    }
    .services-container-section .services-section{
        gap:40px;
    }
    .services-photo-gallery{
       width:95%;
       margin: 0 auto;
    }
    .services-photo-gallery .services-p1 .services-head-g h1{
        font-size:16px;
    }
    .services-container-section .services-section .services-photo-gallery{
        gap:40px;
    }
    .services-photo-gallery .services-p1 .services-g{
        width:300px;
        height:200px;
    }
    .right-icon img{
        width:22px;
        height: 22px;
    }
    .services-desc{
        flex-direction: column;
        width:95%
    }
    .services-para p {
        width:99%;
        padding-bottom:30px;
        line-height:25px;
    }
    .link-icon{
        width:210px;
        height:45px;
        margin-top: 0px;
    }
    .service-link a{
        font-size:15px
    }
    .service-right-icon img {
        width:17px;
        height: 17px;
    }
}