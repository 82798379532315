*{
    margin: 0;
    padding: 0;
}
.mission-container-section{
    width:90%;
    margin: 0 auto; 
    margin-top:9rem;
    margin-bottom:5rem;
}
.mission-container-section .mission-section .mission-des{
    display: flex;
    flex-direction: column;
    gap:30px;
}
.mission-container-section .mission-des .mission-heading h1{
   font-size:50px;
   width:70%;
   font-family: "Raleway", sans-serif;
   
}
.mission-sub_heading span{
    font-family: "DM Sans", sans-serif;
    font-size:20px;
    padding-top:38px;
    font-weight:550;
    padding-bottom:30px;
    margin-left: 15px;
    border-bottom:1px solid #0000007c;
}
.image-list-section{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.mission-para-p{
    display: flex;
    flex-direction: column;
    gap:28px;
    padding-top:38px;
}
 .list-box {
    display: flex;
    flex-direction: row;
    gap:20px;
    padding-left: 18px;
}
.images-box img{
   width:26px;
   height:26px;
}
.image-points p{
    font-family: "DM Sans", sans-serif;
    font-size:17px;
    padding-top:3px;
}

.mission-images {
    display: flex;
    flex-direction: row;
    gap:10px;
  }
  
  .mission-images .img-1, .mission-images .img-2 {
    position: relative; 
  }
  
  .mission-images .img-1 img, .mission-images .img-2 img {
    display: block;
    width: 100%; 
    height: auto; 
  }
  
  .mission-images .img-1::after, .mission-images .img-2::after {
    content: ""; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(195, 195, 195, 0.107); 
    z-index: 1; 
  }
  
  .mission-images .img-1, .mission-images .img-2 {
    width: 370px; 
    height: 360px; 
    position: relative; 
  }
  
  .mission-images .img-2 {
    width: 300px; 
    height: 290px; 
    margin-top:-5rem;
    margin-right:-5rem;
  }
  
  .mission-images .img-1 img {
    height: 100%; 
    object-fit: cover; 
    margin-top:2rem;

  }
  
  .mission-images .img-2 img {
    height: 100%;
    object-fit: cover; 
    box-shadow:1px 3px 3px rgba(0,0,0,0.5)
  }
  
  @media(max-width:768px)
 {
    .mission-container-section{
        margin-top:-30px;
    }
    .mission-container-section .mission-des .mission-heading h1{
        font-size:21px;
        width:90%;
    }
    .mission-sub_heading span{
        font-size:15px;
        margin-left: 0px;
        border-bottom: none;
       
    }
    .hide-img-rep{
        display: none;
    }
    .image-list-section{
        display: flex;
        flex-direction: column;
    }
    .list-box {
        padding-left:11px;
    }
    .mission-para-p{
        padding-top:10px;
    }
    .images-box img{
      padding-top:4px;
      width:22px;
      height: 22px;
    }
    .image-points p{
        padding-top:-5px;
        font-size:16px;
    }
    .mission-images .img-1 img{
        width:100%;
        height:300px;
    }
  }