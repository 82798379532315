*{
    margin: 0;
    padding: 0;
}
.contact-container-section{
    background-color:#cdef5c;
    margin-top:10rem;
    height:650px;
    margin-bottom:5rem;
}
.contact-container-section .contact-section{
    display: flex;
    flex-direction: column;
    gap:30px;
}
.line-angle{
    display: flex;
    justify-content: end;
}
.line-angle img{
    width:630px;
    padding-right:85px;
    height:200px;
}
.contact-heading h1{
    width:40%;
    margin-top: -5rem;
    font-family: "Raleway", sans-serif;
    font-size:60px;
    text-transform: uppercase;
    padding-left:50px;
}
.contact-details{
  width:93%;
  margin: 0 auto;
  margin-top:3rem;
}
.contact-details .contact-grid{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    padding-right:100px;
}
.contact-info,
.contact-additional,
.contact-map{
    display: flex;
    flex-direction: column;
    gap:28px;
}
.contact-info .contact-head h2,
.contact-additional .add-contact-detail h2,
.contact-map .contact-map-details h2{
    font-size:23px;
    font-family: "DM Sans", sans-serif;
    font-weight:800;
    text-transform: uppercase;
    word-spacing:5px;
    letter-spacing: 1px;
}
.contact-para-d,
.add-time-hour{
    display: flex;
    flex-direction: column;
    gap:15px;
}
.contact-para-d p,
.add-time-hour p{
    font-family: "DM Sans", sans-serif;
    font-size:18px;
    word-spacing:3px;
}
.contact-map-loc p a {
    display: inline-block;
    background-color: #fff; 
    color: #000; 
    padding: 12px 20px;
    border-radius: 5px;
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); 
}

.contact-map-loc p a:hover {
    background-color: #000; 
    color: #fff; 
    transform: translateY(-3px); 
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2); 
}

.contact-map-loc p a::after {
    content: ' →'; 
    font-size: 18px;
    transition: margin-left 0.3s ease;
    margin-left: 5px;
}

.contact-map-loc p a:hover::after {
    margin-left: 10px; 
}

@media(max-width:786px)
{
    .contact-container-section{
        margin-top: -3rem;
        height: auto;
        padding-bottom:40px;
    }
    .line-angle img{
       display:none;
    }
    .contact-heading h1{
        margin-top: 0rem;
        font-size:26px;
        width:90%;
        margin: 0 auto;
        padding-left:0px;
        word-spacing:4px;
        line-height:32px;
        letter-spacing: 1px;
    }
    .contact-details{
        margin-top: 0rem;
        width:100%;
    }
    .contact-details .contact-grid{
        flex-direction: column;
        gap:30px;
        padding-left:25px;
    }
    .contact-info,
    .contact-additional,
    .contact-map{
        gap:14px;
    }
    .contact-info .contact-head h2,
    .contact-additional .add-contact-detail h2,
    .contact-map .contact-map-details h2{
        font-size:18px;
        word-spacing:2px;
    }
    .contact-para-d,
    .add-time-hour{
        gap:10px;
    }
    .contact-para-d p,
    .add-time-hour p{
        font-size:16px;
        word-spacing:0px;
        width:100%;
    }
    .contact-map-loc p a{
        font-size:15px;
        padding: 10px 18px;
    }
}